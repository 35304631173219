var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-btn', _vm._b({
    attrs: {
      "disabled": "",
      "x-large": "",
      "block": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('click');
      }
    }
  }, 'v-btn', {
    color: _vm.color,
    loading: _vm.loading
  }, false), [_c('span', {
    staticClass: "grey--text text--lighten-1"
  }, [_vm._v(_vm._s(_vm.$dayjs().hour(_vm.hour).minute(0).format("HH:mm")))]), _vm.stateText ? [_c('span', [_vm._v(_vm._s(_vm.stateText))])] : [_c('v-simple-checkbox', {
    staticClass: "d-block",
    attrs: {
      "hide-details": "",
      "color": "white"
    }
  })]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <client-page>
        <sub-visual sh="공간" tabActive="공간소개" image="/images/place/visual.jpg" />

        <page-section title="공간소개" subtitle="Place Introduction" class="page-section--first">
            <!-- <div class="tit tit--xl font-weight-extrabold text-center mb-18 mb-md-24">COMING SOON</div> -->
            <v-divider class="v-divider--dark" />
            <v-sheet class="font-size-24 font-size-md-28 py-28 py-md-36 text-center">
                <!-- <div>
                    금산군 인재들의 창의 활동과 신활력을 불어 넣는 공간, <br class="d-none d-lg-block" />
                    <strong>우리동네 이음터</strong>가 곧 찾아 옵니다.
                </div> -->
                <div>금산에 활력을 불어넣는 여러분이 주인인 공간, 금산 우리동네 이음터를 소개합니다.</div>
            </v-sheet>
            <v-divider class="v-divider--dark" />
        </page-section>

        <section>
            <v-img eager src="/images/place/place.png"></v-img>
        </section>

        <page-section class="page-section--last pt-0" />

        <page-section id="guide" title="대관신청 안내" subtitle="Reservation Guide" class="page-section--last">
            <v-img max-width="848" src="/images/place/place-step.png" class="mx-auto mb-40 mb-md-60" />
            <v-row class="row--sm">
                <template v-for="(item, index) in items">
                    <v-col :key="index" cols="12" md="6">
                        <v-sheet color="grey lighten-5" rounded="xs" class="pa-18 pa-md-24 h-100">
                            <div class="tit tit--xs font-weight-bold mb-12 mb-md-16">
                                {{ item.title }}
                            </div>
                            <v-row class="row--xxs page-text grey--text">
                                <template v-for="(child, index) in item.children">
                                    <v-col :key="index" cols="12">
                                        <v-row no-gutters>
                                            <v-col cols="auto"><template v-if="child.annotation">※</template><template v-else>·</template>&nbsp;</v-col>
                                            <v-col>
                                                {{ child.text }}
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </template>
                            </v-row>
                        </v-sheet>
                    </v-col>
                </template>
            </v-row>
        </page-section>
    </client-page>
</template>

<script>
import SubVisual from "@/components/client/sub/sub-visual.vue";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import PageSection from "@/components/client/sub/page-section.vue";

export default {
    components: {
        SubVisual,
        ClientPage,
        PageSection,
    },
    data: () => ({
        items: [
            {
                title: "사용료",
                children: [{ text: "무료" }],
            },
            {
                title: "문의",
                children: [{ text: "041-751-7883" }],
            },
            {
                title: "대관 대상",
                children: [
                    { text: "수행 사업과 관련 있는 지역 내 공공기관, 유관기관 및 단체 등" },
                    { text: "기타 공익을 추구하는 건전한 교육 및 강연 행사라고 대관 담당자가 판단하는 경우" },
                    //
                ],
            },
            {
                title: "최대 대관 가능 기간",
                children: [
                    { text: "행사 1회당 1일 이내를 원칙으로 함" },
                    {
                        text: "2일 이상 이용의 경우 사전 협의 후 연장 가능",
                        annotation: true,
                    },
                ],
            },
            {
                title: "제한 대상",
                children: [
                    { text: "수행 사업과 관련이 없는 경우" },
                    { text: "개인적 (동호회, 동아리 등) 활동인 경우" },
                    { text: "공공질서 및 미풍양속을 해할 우려가 있을 경우" },
                    { text: "종교적 행사, 정치적 목적의 행사, 영리 목적 등의 행사인 경우" },
                    { text: "사전 협의 혹은 전산상 취소 절차 없이 1회 이상 예약 시간을 지키지 않거나 실제 예약 시간에 불참하는 경우" },
                    //
                ],
            },
            {
                title: "신청 기준",
                children: [
                    { text: "내부 행사 우선 수용 후 유휴기간에 대관 가능" },
                    { text: "사용 예정일 30일 전부터 일주일 전까지 신청 가능" },
                    {
                        text: "선착순 신청을 원칙으로 함",
                        annotation: true,
                    },
                    { text: "운영시간 (평일 10:00 ~ 17:00) 내에만 대관 가능" },
                    {
                        text: "휴일 및 시간 외 대관의 경우 사전 협의",
                        annotation: true,
                    },
                ],
            },
        ],
    }),
};
</script>

<style lang="scss" scoped>
#guide {
    margin-top: -50px;
    padding-top: 100px;
}
@media (min-width: 768px) {
    #guide {
        margin-top: -60px;
        padding-top: 120px;
    }
}

@media (min-width: 1024px) {
    #guide {
        margin-top: -80px;
        padding-top: 160px;
    }
}
</style>

<template></template>

<script>
import { initRentalSubject } from "@/assets/variables";

export default {
    props: {
        schedules: { type: Array, default: () => [] },
        schedulesOnCloud: { type: Array, default: () => [] },

        subject: { type: Object, default: initRentalSubject },

        date: { type: String, default: Date.now().toDate() },
        hour: { type: [String, Number] },
    },
    computed: {
        schedulesOnTheDate() {
            return [...this.schedules, ...this.schedulesOnCloud.filter(({ _user }) => _user == this.$store.state.payload?._user)].filter(({ date }) => this.$dayjs(date).isSame(this.date, "date"));
        },

        hours() {
            return [...this.subject.rental.businessHours].sort((a, b) => a - b);
        },

        hoursNearby() {
            let hours = [...this.hours];

            let startIndex = this.hours.findIndex((hour) => hour == this.hour);
            let endIndex = startIndex + 2;
            if (0 < startIndex) startIndex -= 1;
            if (hours.length < endIndex) endIndex = hours.length;

            hours = hours.slice(startIndex, endIndex);
            return hours;
        },

        hasAnNearbyHour() {
            if (!this.schedulesOnTheDate.length) return true;
            return this.schedulesOnTheDate.some(({ hour }) => this.hoursNearby.includes(hour));
        },

        hasNearbyHours() {
            const nearbyCount = this.schedulesOnTheDate.filter(({ hour }) => this.hoursNearby.includes(hour)).length;
            return nearbyCount == 3;
        },

        readonly() {
            return !this.hasAnNearbyHour || this.hasNearbyHours;
        },
    },
    mounted() {
        this.emit();
    },
    watch: {
        readonly() {
            this.emit();
        },
    },
    methods: {
        emit() {
            this.$emit("input", this.readonly);
        },
    },
};
</script>

<style>
</style>
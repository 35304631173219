<template>
    <v-btn v-bind="{ color }" x-large block @click="$emit('click')">
        <span class="grey--text text--lighten-1">{{ time.timeStart }} ~ {{ time.timeEnd }}</span>
        <v-simple-checkbox hide-details class="d-block grey--text text--lighten-3" />
    </v-btn>
</template>

<script>
import { initRentalBlockage, initRentalBusinessTime, RENTAL_SCHEDULE_STATES } from "@/assets/variables";

export default {
    props: {
        blockage: { type: Object, default: initRentalBlockage },
        schedulesOnCloud: { type: Array, default: () => [] },

        date: { type: String },
        time: { type: Object, default: initRentalBusinessTime },
    },
    computed: {
        scheduleOnCloud() {
            return this.schedulesOnCloud.find((schedule) => this.$dayjs(schedule.date).isSame(this.date, "date") && schedule.timeStart == this.time.timeStart && schedule.timeEnd == this.time.timeEnd);
        },

        isClosed() {
            const [hour, minute] = this.time.timeEnd.split(":");
            return this.$dayjs(this.date).hour(hour).minute(minute).isBefore(this.$dayjs());
        },

        isUserSchedule() {
            return this.scheduleOnCloud?._user == this.$store.state?.payload?._user;
        },

        color() {
            if (this.isUserSchedule) return "secondary lighten-5";
            else return "grey lighten-4";
        },
    },
};
</script>

<style lang="scss" scoped>
</style>
<template>
    <div v-if="schedules.length">
        <template v-if="subject.rental.type == RENTAL_TYPES.BY_HOUR.value">
            <v-card outlined rounded="xs" class="mt-10">
                <div class="px-20 px-md-36 py-16">
                    <v-row justify="space-between" align="center">
                        <v-col cols="auto">
                            <i class="icon icon-check mr-8" />
                        </v-col>
                        <v-col cols="auto">
                            <p class="page-text page-text--lg line-height-15">
                                <span> {{ date }} </span>
                                <b class="ml-10"> {{ hour }} </b>
                            </p>
                        </v-col>
                    </v-row>
                </div>
            </v-card>
        </template>
        <template v-else>
            <v-card v-for="(item, index) in schedules" :key="index" outlined rounded class="mt-10">
                <div class="px-20 px-md-36 py-16">
                    <v-layout justify-space-between align-center>
                        <i class="icon icon-check mr-8" />
                        <p class="page-text page-text--lg line-height-15">
                            <template v-if="subject.rental.type == RENTAL_TYPES.BY_DATE.value">
                                {{ $dayjs(item.date).format("YYYY년 M월 D일") }}
                            </template>
                            <template v-if="subject.rental.type == RENTAL_TYPES.BY_HOUR.value">
                                {{ $dayjs(item.date).hour(item.hour).format("YYYY년 M월 D일 HH:mm (dd)") }}
                            </template>
                            <template v-if="subject.rental.type == RENTAL_TYPES.BY_TIME.value">
                                {{ $dayjs(item.date).format("YYYY년 M월 D일") }}
                                <b class="ml-10"> {{ item.timeStart }} ~ {{ item.timeEnd }} </b>
                            </template>
                        </p>
                    </v-layout>
                </div>
            </v-card>
        </template>
    </div>
</template>

<script>
import { initRentalSubject, RENTAL_TYPES } from "@/assets/variables";

export default {
    props: {
        subject: { type: Object, default: initRentalSubject },
        schedules: { type: Array, default: () => [] },
    },
    data: () => ({
        RENTAL_TYPES,
    }),
    computed: {
        date() {
            return this.schedules.map((item) => this.$dayjs(item.date).hour(item.hour).format("YYYY년 M월 D일")).pop();
        },
        hour() {
            let hours = this.schedules.map(({ hour }) => hour).sort((a, b) => a - b);
            return `${hours.at(0)}:00 ~ ${hours.at(-1)}:59`;
        },
    },
};
</script>

<style lang="scss" scoped>
i.icon {
    color: var(--v-primary-base);
}
</style>
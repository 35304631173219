<template></template>

<script>
import api from "@/api";
import { initRentalLimitation, initRentalSubject, RENTAL_LIMITATION_TARGET_TYPES, RENTAL_LIMITATION_TYPES, RENTAL_SCHEDULE_STATES, RENTAL_TYPES } from "@/assets/variables";

export default {
    props: {
        value: { type: Boolean, default: false },
        mode: { type: String, default: "self" },

        date: { type: String },
        subject: { type: Object, default: initRentalSubject },
        schedules: { type: Array, default: () => [] },
    },
    data: () => ({
        limitation__site: initRentalLimitation(),
        schedulesOnCloud: [],
    }),
    computed: {
        limitation() {
            if (this.mode == "self") return initRentalLimitation(this.subject.limitationPerWeek__self);
            if (this.mode == "type") return initRentalLimitation(this.subject.limitationPerWeek__type);
            if (this.mode == "site") return initRentalLimitation(this.limitation__site);
        },
        countMaximum() {
            switch (this.subject.rental.type) {
                case RENTAL_TYPES.BY_DATE.value: {
                    return this.limitation?.dates;
                }
                case RENTAL_TYPES.BY_HOUR.value: {
                    return this.limitation?.hours;
                }
                case RENTAL_TYPES.BY_TIME.value: {
                    return this.limitation?.times;
                }
                default: {
                    return undefined;
                }
            }
        },
        dateStart() {
            if (this.limitation?._id) {
                let { resetDay } = this.limitation;
                let dateStart = this.$dayjs(this.date).toISOString();

                let weekdays = new Array(7).fill().map((_, index) => index);
                const hasResetDay = weekdays.includes(resetDay);

                if (hasResetDay) {
                    while (this.$dayjs(dateStart).day() != resetDay) {
                        dateStart = this.$dayjs(dateStart).subtract(1, "day").startOf("day").toISOString();
                    }
                    return dateStart;
                }
            }
            return null;
        },
        dateEnd() {
            if (this.dateStart) {
                return this.$dayjs(this.dateStart).add(6, "days").endOf("day").toISOString();
            }
            return null;
        },
        params() {
            if (this.limitation?._id) {
                let { APPLIED, APPROVED } = RENTAL_SCHEDULE_STATES;

                const params = {
                    dates: [this.dateStart, this.dateEnd],
                    state: [APPLIED.value, APPROVED.value],
                    rentalType: this.subject.rental.type,
                };

                switch (this.mode) {
                    case "self": {
                        params._subject = this.limitation?.target?._subjects || [];
                        break;
                    }
                    case "type": {
                        params.subjectType = this.limitation?.target?.subjectTypes || [];
                        break;
                    }
                }

                return params;
            }
        },
        countSelected() {
            if (this.limitation?._id) {
                return [...this.schedules.filter(({ date }) => !this.$dayjs(date).isSame(this.date, "date")), ...this.schedulesOnCloud].reduce((dates, { date }) => {
                    let isAfterStart = !this.$dayjs(date).isBefore(this.dateStart, "date");
                    let isBeforeEnd = !this.$dayjs(date).isAfter(this.dateEnd, "date");
                    if (isAfterStart && isBeforeEnd) {
                        date = date.toDate();
                        if (!dates.includes(date)) dates.push(date);
                    }
                    return dates;
                }, []).length;
            } else return 0;
        },
        limitationMet() {
            if (this.countMaximum) {
                if (this.countMaximum <= this.countSelected) return true;
            }
            return false;
        },
    },
    mounted() {
        this.getLimitation().then(this.getSchedules);
    },
    watch: {
        "subject._id"() {
            this.getLimitation().then(this.getSchedules);
        },
        dateStart() {
            this.getSchedules();
        },
        limitationMet() {
            if (this.value != this.limitationMet) this.$emit("input", this.limitationMet);
        },
    },
    methods: {
        async getLimitation() {
            if (this.mode == "site") {
                const { limitation } = await api.v1.rental.limitations.get({
                    type: RENTAL_LIMITATION_TYPES.PER_WEEK.value,
                    targetType: RENTAL_LIMITATION_TARGET_TYPES.ENTIRE_ASSET.value,
                });
                this.limitation__site = initRentalLimitation(limitation);
            }
        },

        async getSchedules() {
            if (this.limitation?._id && this.$store.state.payload?._user) {
                const { params } = this;
                let { schedules } = await api.v1.me.rental.schedules.gets({
                    params,
                });

                this.schedulesOnCloud = schedules;
            }
        },
    },
};
</script>

<style></style>

<template>
    <v-btn disabled x-large block v-bind="{ color, loading }" @click="$emit('click')">
        <span class="grey--text text--lighten-1">{{ $dayjs().hour(hour).minute(0).format("HH:mm") }}</span>
        <template v-if="stateText">
            <span>{{ stateText }}</span>
        </template>
        <template v-else>
            <v-simple-checkbox hide-details color="white" class="d-block" />
        </template>
    </v-btn>
</template>

<script>
import { RENTAL_SCHEDULE_STATES } from "@/assets/variables";

export default {
    props: {
        schedulesOnCloud: { type: Array, default: () => [] },

        date: { type: String },
        hour: { type: Number },

        loading: { type: Boolean, default: false },
    },
    computed: {
        scheduleOnCloud() {
            return this.schedulesOnCloud.find((schedule) => schedule.hour == this.hour);
        },
        isClosed() {
            return this.$dayjs(this.date).set("hour", this.hour).isBefore(this.$dayjs());
        },
        isApproved() {
            return this.scheduleOnCloud?.state == RENTAL_SCHEDULE_STATES.APPROVED.value;
        },
        stateText() {
            if (this.isClosed || this.isApproved) return "(마감)";
            if (this.scheduleOnCloud) return "(대기)";
        },
        color() {
            if (this.isClosed || this.isApproved) return "grey lighten-4";
            if (!this.isClosed && !this.isApproved) return "secondary lighten-5";
        },
    },
};
</script>

<style lang="scss" scoped>
</style>
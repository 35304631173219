<template>
    <div class="swiper-container" :class="`swiper-container--${className}`" ref="swiper">
        <div class="swiper-wrapper">
            <template v-for="photo in item.photos">
                <div class="swiper-slide" :key="photo._id">
                    <v-card flat :img="photo.src" class="place-list_image rounded-sm">
                        <v-responsive :aspect-ratio="57 / 38" />
                    </v-card>

                    <div class="swiper-pagination" :class="`swiper-pagination--${className}`" />
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { initRentalSubject } from "@/assets/variables";

export default {
    props: {
        item: { type: Object, default: initRentalSubject },
    },
    data: () => ({
        className: Math.random().toString().slice(2),
    }),
    methods: {
        init() {
            const intervalId = setInterval(() => {
                const swiper = this.$refs["swiper"];
                if (!swiper) return;

                clearInterval(intervalId);

                setTimeout(() => {
                    new Swiper(`.swiper-container--${this.className}`, {
                        effect: "fade",
                        autoplay: {
                            delay: 3000,
                            disableOnInteraction: false,
                        },
                        pagination: {
                            el: `.swiper-pagination--${this.className}`,
                            clickable: true,
                        },
                        speed: 500,
                        loop: true,
                    });
                }, 1000);
            }, 10);
        },
    },
    mounted() {
        this.init();
    },
};
</script>


<style lang="scss" scoped>
.swiper-slide {
    position: relative;
}
.swiper-pagination {
    left: 24px;
    bottom: 24px;
    top: initial;
}
.swiper-pagination-bullets {
    margin: -6px;
    ::v-deep {
        .swiper-pagination-bullet {
            margin: 6px;
            background-color: #fff;
            width: 8px;
            height: 8px;
            opacity: 0.4;
            &-active {
                width: 20px;
                height: 20px;
                background-color: transparent;
                border: 1px solid #fff;
                position: relative;
                opacity: 1;
                &::after {
                    content: "";
                    width: 8px;
                    height: 8px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    background-color: #fff;
                    border-radius: 50%;
                }
            }
        }
    }
}
</style>
<template></template>

<script>
import { initRentalBusinessTime, initRentalSubject } from "@/assets/variables";

export default {
    props: {
        schedules: { type: Array, default: () => [] },
        schedulesOnCloud: { type: Array, default: () => [] },

        subject: { type: Object, default: initRentalSubject },

        date: { type: String, default: Date.now().toDate() },
        time: { type: Object, default: initRentalBusinessTime },
    },
    computed: {
        schedulesOnTheDate() {
            return [...this.schedules, ...this.schedulesOnCloud.filter(({ _user }) => _user == this.$store.state.payload?._user)].filter(({ date }) => this.$dayjs(date).isSame(this.date, "date"));
        },

        times() {
            return [...this.subject.rental.businessTimes].filter(({ day }) => this.$dayjs(this.date).day() == day).sort((a, b) => +a.timeStart.replace(":", "") - +b.timeStart.replace(":", ""));
        },

        timesNearby() {
            let times = [...this.times];

            let startIndex = this.times.findIndex(({ timeStart }) => timeStart == this.time.timeStart);
            let endIndex = startIndex + 2;
            if (0 < startIndex) startIndex -= 1;
            if (times.length < endIndex) endIndex = times.length;

            times = times.slice(startIndex, endIndex);
            return times;
        },

        hasAnNearbyTime() {
            if (!this.schedulesOnTheDate.length) return true;
            return this.schedulesOnTheDate.some(({ timeStart }) => this.timesNearby.some((time) => time.timeStart == timeStart));
        },

        hasNearbyTimes() {
            const nearbyCount = this.schedulesOnTheDate.filter(({ timeStart }) => this.timesNearby.some((time) => time.timeStart == timeStart)).length;
            return nearbyCount == 3;
        },

        readonly() {
            return !this.hasAnNearbyTime || this.hasNearbyTimes;
        },
    },
    mounted() {
        this.emit();
    },
    watch: {
        readonly() {
            this.emit();
        },
    },
    methods: {
        emit() {
            this.$emit("input", this.readonly);
        },
    },
};
</script>

<style>
</style>
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('rental-form-calendar', _vm._b({
    on: {
      "input": function (date) {
        return _vm.$emit('input', date);
      }
    }
  }, 'rental-form-calendar', Object.assign({}, _vm.$attrs, {
    value: _vm.value,
    subject: _vm.subject,
    color: 'primary'
  }), false));

}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <v-row class="ma-n20">
        <v-col cols="12" md="7" class="pa-20">
            <div class="swiper-container">
                <div class="swiper-wrapper">
                    <template v-for="photo in subject.photos">
                        <div class="swiper-slide" :key="photo._id">
                            <v-card flat :img="photo.src" class="rounded-sm">
                                <v-responsive :aspect-ratio="69 / 46" />
                            </v-card>

                            <div class="swiper-pagination" />
                        </div>
                    </template>
                </div>
            </div>
        </v-col>

        <v-col cols="12" md="5" class="pa-20">
            <v-sheet rounded="pill" color="grey lighten-5" class="px-16 py-8 tit tit--sm font-weight-bold">{{ subject.name }}</v-sheet>
            <v-sheet class="pt-16 px-16 page-text page-text--lg">
                <ul class="information-list">
                    <template v-for="(item, index) in items">
                        <li :key="index">
                            <v-row no-gutters>
                                <v-col cols="auto" class="mr-10 font-weight-medium">
                                    {{ item.title }}
                                </v-col>
                                <v-col class="grey--text">
                                    {{ item.text }}
                                </v-col>
                            </v-row>
                        </li>
                    </template>
                </ul>
            </v-sheet>
        </v-col>
    </v-row>
</template>

<script>
import { initRentalSubject } from "@/assets/variables";

export default {
    props: {
        subject: { type: Object, default: initRentalSubject },
    },
    computed: {
        items() {
            return [
                { title: "위치", text: this.subject.location },
                { title: "면적", text: this.subject.dimension },
                { title: "수용인원", text: this.subject.capacity },
                { title: "부대시설", text: this.subject.equipment },
                { title: "유의사항", text: this.subject.guidenote },
            ];
        },
    },
    methods: {
        init() {
            this.$nextTick(() => {
                new Swiper(".swiper-container", {
                    effect: "fade",
                    autoplay: {
                        delay: 3000,
                        disableOnInteraction: false,
                    },
                    pagination: {
                        el: ".swiper-pagination",
                        clickable: true,
                    },
                    speed: 500,
                    loop: true,
                });
            });
        },
    },
    watch: {
        subject() {
            this.init();
        },
    },
    mounted() {
        this.init();
    },
};
</script>

<style lang="scss" scoped>
.swiper-slide {
    position: relative;
}
.swiper-pagination {
    left: 24px;
    bottom: 24px;
    top: initial;
}
.swiper-pagination-bullets {
    margin: -6px;
    ::v-deep {
        .swiper-pagination-bullet {
            margin: 6px;
            background-color: #fff;
            width: 8px;
            height: 8px;
            opacity: 0.4;
            &-active {
                width: 20px;
                height: 20px;
                background-color: transparent;
                border: 1px solid #fff;
                position: relative;
                opacity: 1;
                &::after {
                    content: "";
                    width: 8px;
                    height: 8px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    background-color: #fff;
                    border-radius: 50%;
                }
            }
        }
    }
}

.information-list {
    > li {
        margin-bottom: 8px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}
</style>
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm.isClosed ? _c('schedule-time-disabled', _vm._b({
    on: {
      "click": _vm.alert
    }
  }, 'schedule-time-disabled', {
    blockage: _vm.blockage,
    schedulesOnCloud: _vm.schedulesOnCloud,
    date: _vm.date,
    time: _vm.time
  }, false)) : _c('schedule-time-checkbox', _vm._g(_vm._b({}, 'schedule-time-checkbox', {
    value: _vm.value,
    subject: _vm.subject,
    limitationMet: _vm.limitationMet,
    schedule: _vm.schedule,
    loading: _vm.loading,
    readonly: _vm.readonly
  }, false), {
    push: _vm.push,
    pull: _vm.pull
  })), _c('schedule-time-validate-linked', _vm._b({
    model: {
      value: _vm.readonly__liked,
      callback: function ($$v) {
        _vm.readonly__liked = $$v;
      },
      expression: "readonly__liked"
    }
  }, 'schedule-time-validate-linked', {
    schedules: _vm.value,
    schedulesOnCloud: _vm.schedulesOnCloud,
    subject: _vm.subject,
    date: _vm.date,
    time: _vm.time
  }, false))], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
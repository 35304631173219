var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "ma-n20"
  }, [_c('v-col', {
    staticClass: "pa-20",
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('div', {
    staticClass: "swiper-container"
  }, [_c('div', {
    staticClass: "swiper-wrapper"
  }, [_vm._l(_vm.subject.photos, function (photo) {
    return [_c('div', {
      key: photo._id,
      staticClass: "swiper-slide"
    }, [_c('v-card', {
      staticClass: "rounded-sm",
      attrs: {
        "flat": "",
        "img": photo.src
      }
    }, [_c('v-responsive', {
      attrs: {
        "aspect-ratio": 69 / 46
      }
    })], 1), _c('div', {
      staticClass: "swiper-pagination"
    })], 1)];
  })], 2)])]), _c('v-col', {
    staticClass: "pa-20",
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('v-sheet', {
    staticClass: "px-16 py-8 tit tit--sm font-weight-bold",
    attrs: {
      "rounded": "pill",
      "color": "grey lighten-5"
    }
  }, [_vm._v(_vm._s(_vm.subject.name))]), _c('v-sheet', {
    staticClass: "pt-16 px-16 page-text page-text--lg"
  }, [_c('ul', {
    staticClass: "information-list"
  }, [_vm._l(_vm.items, function (item, index) {
    return [_c('li', {
      key: index
    }, [_c('v-row', {
      attrs: {
        "no-gutters": ""
      }
    }, [_c('v-col', {
      staticClass: "mr-10 font-weight-medium",
      attrs: {
        "cols": "auto"
      }
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('v-col', {
      staticClass: "grey--text"
    }, [_vm._v(" " + _vm._s(item.text) + " ")])], 1)], 1)];
  })], 2)])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <client-page>
        <sub-visual sh="공간" tabActive="공간 소개" image="/images/place/visual.jpg" />

        <page-section title="대관신청" subtitle="Reservation" button="대관신청 안내" buttonLink="/place/introduction#guide" class="page-section--first page-section--last">
            <v-row class="row--xl">
                <template v-for="item in subjects">
                    <v-col cols="12" md="6" :key="item._id">
                        <place-list-item v-bind="{ item }" />
                    </v-col>
                </template>
            </v-row>
            <div class="v-pagination-wrap">
                <v-pagination :value="page" :length="pageCount" :total-visible="20" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
            </div>
        </page-section>
    </client-page>
</template>

<script>
import api from "@/api";

import SubVisual from "@/components/client/sub/sub-visual.vue";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import PageSection from "@/components/client/sub/page-section.vue";
import PlaceListItem from "@/components/client/place/place-list-item.vue";

export default {
    components: {
        SubVisual,
        ClientPage,
        PageSection,
        PlaceListItem,
    },
    data: () => ({
        subjects: [],

        limit: 10,
        summary: { totalCount: 0 },

        loading: false,
    }),
    computed: {
        page() {
            return +(this.$route.query.page || "1");
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        headers() {
            let { limit } = this;
            return { skip: (this.page - 1) * limit, limit };
        },
        params() {
            let { ...query } = this.$route.query;

            delete query.page;

            return { ...query, shows: true };
        },
    },
    methods: {
        async search() {
            let { headers, params } = this;
            let { summary, subjects } = await api.v1.rental.subjects.gets({ headers, params });
            this.summary = summary;
            this.subjects = subjects;
        },
    },
    mounted() {
        this.search();
    },
    watch: {
        params() {
            this.search();
        },
    },
};
</script>

var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "swiper",
    staticClass: "swiper-container",
    class: `swiper-container--${_vm.className}`
  }, [_c('div', {
    staticClass: "swiper-wrapper"
  }, [_vm._l(_vm.item.photos, function (photo) {
    return [_c('div', {
      key: photo._id,
      staticClass: "swiper-slide"
    }, [_c('v-card', {
      staticClass: "place-list_image rounded-sm",
      attrs: {
        "flat": "",
        "img": photo.src
      }
    }, [_c('v-responsive', {
      attrs: {
        "aspect-ratio": 57 / 38
      }
    })], 1), _c('div', {
      staticClass: "swiper-pagination",
      class: `swiper-pagination--${_vm.className}`
    })], 1)];
  })], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <v-card :disabled="readonly" flat>
        <v-btn v-bind="{ loading }" :class="{ 'v-btn--active': isChecked }" x-large block outlined color="grey lighten-4" @click="toggleSchedule">
            <span class="v-btn__text">{{ schedule.timeStart }} ~ {{ schedule.timeEnd }}</span>
            <v-simple-checkbox :value="isChecked" v-bind="{ disabled }" hide-details color="white" class="d-block" @click="toggleSchedule" />
        </v-btn>
    </v-card>
</template>

<script>
import { initRentalSubject } from "@/assets/variables";
export default {
    props: {
        value: { type: Array, default: () => [] },
        subject: { type: Object, default: initRentalSubject },
        limitationMet: { type: Boolean, default: false },

        schedule: { type: Object, default: () => ({}) },

        loading: { type: Boolean, default: false },
        readonly: { type: Boolean, default: false },
    },
    computed: {
        isChecked() {
            let { _subject, date, timeStart, timeEnd } = this.schedule;
            return this.value.some((schedule) => schedule._subject == _subject && schedule.date == date && schedule.timeStart == timeStart && schedule.timeEnd == timeEnd);
        },
        disabled() {
            return !this.isChecked && this.limitationMet;
        },
    },
    methods: {
        toggleSchedule() {
            if (this.isChecked) this.$emit("pull");
            else if (this.limitationMet) alert("예약할 수 있는 회수를 초과하셨습니다");
            else this.$emit("push");
        },
    },
};
</script>

<style lang="scss" scoped>
.v-btn:not(.v-btn--fab, .v-btn--icon).v-size--x-large {
    .v-icon {
        color: var(--v-grey-lighten3);
    }
}
.v-btn__text {
    color: var(--v-grey-darken4);
}
.v-btn.v-btn--active {
    .v-btn__text {
        color: #fff;
    }
}
</style>

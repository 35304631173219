var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "place-list-item",
    attrs: {
      "flat": "",
      "tile": ""
    }
  }, [_c('item-images', _vm._b({
    staticClass: "mb-20"
  }, 'item-images', {
    item: _vm.item
  }, false)), _c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', [_c('v-sheet', {
    staticClass: "place-list-item__title tit tit--sm",
    attrs: {
      "rounded": "pill",
      "color": "grey lighten-5"
    }
  }, [_vm._v(_vm._s(_vm.item.name))])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "rounded-pill",
    attrs: {
      "large": "",
      "width": "120",
      "color": "primary",
      "to": `/place/reservation/${_vm.item._id}`
    }
  }, [_c('span', {
    staticClass: "tit tit--xxs font-weight-semibold"
  }, [_vm._v("대관예약")])])], 1)], 1), _c('v-sheet', {
    staticClass: "pt-16 px-16 page-text page-text--lg"
  }, [_c('div', {
    staticClass: "pb-8"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "mr-10",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("위치")]), _c('v-col', {
    staticClass: "grey--text"
  }, [_vm._v(_vm._s(_vm.item.location))])], 1)], 1), _c('div', [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "mr-10",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("수용인원")]), _c('v-col', {
    staticClass: "grey--text"
  }, [_vm._v(_vm._s(_vm.item.capacity))])], 1)], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <v-col cols="12">
        <schedule-time-disabled v-if="isClosed" v-bind="{ blockage, schedulesOnCloud, date, time }" @click="alert" />
        <schedule-time-checkbox v-else v-bind="{ value, subject, limitationMet, schedule, loading, readonly }" v-on="{ push, pull }" />
        <schedule-time-validate-linked v-model="readonly__liked" v-bind="{ schedules: value, schedulesOnCloud, subject, date, time }" />
    </v-col>
</template>

<script>
import { initRentalBusinessTime, initRentalSubject } from "@/assets/variables";

import ScheduleTimeDisabled from "./schedule-time-disabled.vue";
import ScheduleTimeCheckbox from "./schedule-time-checkbox.vue";
import ScheduleTimeValidateLinked from "./schedule-time-validate-linked.vue";

export default {
    components: {
        ScheduleTimeDisabled,
        ScheduleTimeCheckbox,
        ScheduleTimeValidateLinked,
    },
    props: {
        value: { type: Array, default: () => [] }, // schedules

        subject: { type: Object, default: initRentalSubject },

        blockages: { type: Array, default: () => [] },
        schedulesOnCloud: { type: Array, default: () => [] },

        limitationMet: { type: Boolean, default: false },

        _user: { type: String },
        date: { type: String, default: Date.now().toDate() },
        time: { type: Object, default: initRentalBusinessTime },

        loading: { type: Boolean, default: false },
    },
    data: () => ({
        schedules: [],
        readonly__liked: false,
    }),
    computed: {
        schedule() {
            const { _id: _subject } = this.subject;
            const { _user, date } = this;
            const { timeStart, timeEnd } = this.time;

            return { _user, _subject, date, timeStart, timeEnd };
        },

        startsAt() {
            const [hour, minute] = this.schedule.timeStart.split(":");
            return this.$dayjs(this.date).hour(hour).minute(minute).toISOString();
        },

        endsAt() {
            const [hour, minute] = this.schedule.timeEnd.split(":");
            return this.$dayjs(this.date).hour(hour).minute(minute).toISOString();
        },

        blockage() {
            return this.blockages.find((blockage) => {
                if (!blockage.startsAt && !blockage.endsAt) return true;

                const scheduleStartBeforeBlockageEnd = blockage.endsAt ? this.$dayjs(this.startsAt).isBefore(blockage.endsAt) : true;
                const scheduleEndAfterBlockageStart = blockage.startsAt ? this.$dayjs(this.endsAt).isAfter(blockage.startsAt) : true;

                return scheduleStartBeforeBlockageEnd && scheduleEndAfterBlockageStart;
            });
        },

        hasTimePassed() {
            const [hour, minute] = this.schedule.timeStart.split(":");
            return this.$dayjs().isAfter(this.startsAt);
        },

        hasDatePassed() {
            return this.$dayjs(this.date).subtract(1, "day").hour(18).minute(0).isBefore(this.$dayjs());
        },

        hasAnSchedule() {
            return this.schedulesOnCloud.some(({ date, timeStart, timeEnd }) => this.$dayjs(this.date).isSame(date, "date") && timeStart == this.schedule.timeStart && timeEnd == this.schedule.timeEnd);
        },

        readonly() {
            return this.readonly__liked;
        },

        isClosed() {
            return this.hasDatePassed || this.hasTimePassed || this.hasAnSchedule || !!this.blockage;
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.schedules = [...this.value];
        },

        emit() {
            this.$emit("input", this.schedules);
        },

        push() {
            this.schedules = [...this.schedules, this.schedule];

            this.emit();
        },

        pull() {
            const { _subject, date, timeStart, timeEnd } = this.schedule;

            const index = this.schedules.findIndex((item) => item._subject == _subject && item.date == date && item.timeStart == timeStart && item.timeEnd == timeEnd);
            if (index > -1) this.schedules.splice(index, 1);

            this.emit();
        },

        alert() {
            if (this.hasDatePassed) {
                alert("사용 예약은 전일 18시 이전까지만 가능합니다");
            }
        },
    },
};
</script>

<style>
</style>
<template>
    <v-card flat tile class="place-list-item">
        <item-images v-bind="{ item }" class="mb-20" />
        <v-row align="center" class="row--xxs">
            <v-col>
                <v-sheet rounded="pill" color="grey lighten-5" class="place-list-item__title tit tit--sm">{{ item.name }}</v-sheet>
            </v-col>
            <v-col cols="auto">
                <v-btn large width="120" color="primary" class="rounded-pill" :to="`/place/reservation/${item._id}`">
                    <span class="tit tit--xxs font-weight-semibold">대관예약</span>
                </v-btn>
            </v-col>
        </v-row>
        <v-sheet class="pt-16 px-16 page-text page-text--lg">
            <div class="pb-8">
                <v-row no-gutters>
                    <v-col cols="auto" class="mr-10">위치</v-col>
                    <v-col class="grey--text">{{ item.location }}</v-col>
                </v-row>
            </div>
            <div>
                <v-row no-gutters>
                    <v-col cols="auto" class="mr-10">수용인원</v-col>
                    <v-col class="grey--text">{{ item.capacity }}</v-col>
                </v-row>
            </div>
        </v-sheet>
    </v-card>
</template>

<script>
import { initRentalSubject } from "@/assets/variables";

import ItemImages from "./list-item/item-images.vue";

export default {
    props: {
        item: { type: Object, default: initRentalSubject },
    },
    components: {
        ItemImages,
    },
};
</script>

<style lang="scss" scoped>
.place-list-item {
    &__title {
        height: 44px;
        font-weight: 700;
        padding: 8px 16px;
        display: flex;
        align-items: center;
    }
}
@media (min-width: 768px) {
    .place-list-item {
        &__title {
            height: 48px;
        }
    }
}
</style>

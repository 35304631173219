<template>
    <client-page>
        <sub-visual sh="공간" tabActive="공간 소개" image="/images/place/visual.jpg" />

        <page-section title="대관신청" subtitle="Reservation" class="page-section--first page-section--last">
            <v-row class="ma-n20">
                <v-col cols="12" class="pa-20">
                    <place-form-info v-bind="{ subject }" />
                </v-col>
                <v-col cols="12" class="pa-20">
                    <div class="tit tit--sm font-weight-bold pb-18 pb-md-24">예약일시</div>
                    <v-row class="row--xl">
                        <v-col cols="12" xl="7">
                            <v-card rounded outlined elevation="4" v-bind="{ disabled, loading }">
                                <div class="pa-16 pa-md-30 py-xl-80">
                                    <v-sheet max-width="772" class="mx-auto">
                                        <rental-form-calendar
                                            v-model="date"
                                            v-bind="{
                                                subject,
                                                disabled: disabled_calendar,
                                            }"
                                        />
                                    </v-sheet>
                                </div>
                            </v-card>
                        </v-col>
                        <v-col cols="12" xl="5">
                            <v-sheet>
                                <v-row align="end" class="row--x-small">
                                    <v-col cols="12" md="">
                                        <p class="page-text page-text--lg font-weight-bold primary--text">이용시간을 선택해주세요</p>
                                    </v-col>
                                    <v-col cols="12" md="auto">
                                        <v-layout justify-end>
                                            <v-layout justify-end align-center class="mr-12 mr-md-20" style="flex: unset">
                                                <p class="page-text grey--text line-height-15">예약가능</p>
                                                <span class="state-bar"></span>
                                            </v-layout>
                                            <v-layout justify-end align-center style="flex: unset">
                                                <p class="page-text grey--text line-height-15">예약불가</p>
                                                <span class="state-bar grey lighten-4"></span>
                                            </v-layout>
                                        </v-layout>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-card flat v-bind="{ disabled }">
                                            <rental-form-schedules v-model="schedules" v-bind="{ date, subject, blockages, schedulesOnCloud, limitationMet, loading }" />
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-sheet>
                            <v-sheet class="mt-20 mt-md-40">
                                <p class="page-text page-text--lg font-weight-bold primary--text">선택내용을 확인해주세요</p>
                                <rental-form-preview v-bind="{ subject, schedules }" class="mt-8 mt-md-16" />
                            </v-sheet>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" class="pa-20">
                    <div class="tit tit--sm font-weight-bold pb-18 pb-md-24">사용인원</div>
                    <v-textarea v-model="desc.headcount" no-resize outlined hide-details height="180" placeholder="사용인원을 입력해주세요" class="v-textarea--lg rounded" />
                </v-col>
                <v-col cols="12" class="pa-20">
                    <div class="tit tit--sm font-weight-bold pb-18 pb-md-24">대관목적</div>
                    <v-textarea v-model="desc.objective" no-resize outlined hide-details height="180" placeholder="사용목적을 입력해주세요" class="v-textarea--lg rounded" v-bind="{ disabled }" />
                </v-col>
                <v-col cols="12" class="pa-20">
                    <v-row justify="center" class="row--xxs">
                        <v-col cols="auto">
                            <v-btn large rounded outlined color="grey lighten-4" @click="$router.go(-1)">
                                <span class="grey--text" v-bind="{ loading }">취소</span>
                            </v-btn>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn large rounded color="primary" v-bind="{ loading, disabled }" @click="save">
                                <span>예약하기</span>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </page-section>

        <dialogue width="800" v-model="showsDialog">
            <v-sheet min-height="348" class="d-flex align-center pb-56">
                <v-container class="text-center">
                    <v-icon size="64" color="primary" class="mb-20 mb-md-40">mdi-checkbox-marked-circle-outline</v-icon>
                    <div class="tit tit--sm font-weight-bold">대관예약 신청이 완료되었습니다.</div>
                </v-container>
            </v-sheet>
        </dialogue>

        <template v-if="subject._id">
            <rental-subject-blockages
                v-model="blockages"
                v-bind="{
                    date,
                    subject,
                }"
                v-on="{ setLoading: (value) => (loading_blockages = value) }"
            />

            <!-- <rental-schedules-on-cloud
                v-model="hasAnScheduleOnDate"
                v-bind="{
                    date,
                    subject,
                }"
                v-on="{ setLoading: (value) => (loading_schedulesOnCloud = value) }"
            /> -->

            <rental-subject-schedules-on-cloud
                v-model="schedulesOnCloud"
                v-bind="{
                    date,
                    subject,
                }"
                v-on="{ setLoading: (value) => (loading_subjectSchedulesOnCloud = value) }"
            />

            <!-- <rental-subject-limitation-per-date
                v-model="limitationPerDateMet"
                v-bind="{
                    date,
                    subject,
                    schedules,
                }"
                v-on="{ setLoading: (value) => (loading_subjectlimitationPerDate = value) }"
            /> -->

            <!-- <rental-subject-limitation-per-week
                v-model="limitationPerWeekMet__self"
                mode="self"
                v-bind="{
                    date,
                    subject,
                    schedules,
                }"
                v-on="{ setLoading: (value) => (loading_subjectlimitationPerWeekSelf = value) }"
            />
            <rental-subject-limitation-per-week
                v-model="limitationPerWeekMet__type"
                mode="type"
                v-bind="{
                    date,
                    subject,
                    schedules,
                }"
                v-on="{ setLoading: (value) => (loading_subjectlimitationPerWeekType = value) }"
            />
            <rental-subject-limitation-per-week
                v-model="limitationPerWeekMet__site"
                mode="site"
                v-bind="{
                    date,
                    subject,
                    schedules,
                }"
                v-on="{ setLoading: (value) => (loading_subjectlimitationPerWeekSite = value) }"
            /> -->
        </template>
    </client-page>
</template>

<script>
import api from "@/api";
import dayjs from "dayjs";
import { mapGetters } from "vuex";
import { initRentalSubject } from "@/assets/variables/inits";

import SubVisual from "@/components/client/sub/sub-visual.vue";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import PageSection from "@/components/client/sub/page-section.vue";

import RentalSubjectBlockages from "@/components/client/rental/rental-subject-blockages.vue";
// import RentalSchedulesOnCloud from "@/components/client/rental/rental-schedules-on-cloud.vue";
import RentalSubjectSchedulesOnCloud from "@/components/client/rental/rental-subject-schedules-on-cloud.vue";
// import RentalSubjectLimitationPerDate from "@/components/client/rental/rental-subject-limitation-per-date.vue";
import RentalSubjectLimitationPerWeek from "@/components/client/rental/rental-subject-limitation-per-week.vue";

import RentalFormPreview from "@/components/client/rental/form/rental-form-preview.vue";
import RentalFormCalendar from "@/components/client/rental/form/rental-form-calendar.vue";
import RentalFormSchedules from "@/components/client/rental/form/rental-form-schedules.vue";

import Dialogue from "@/components/client/dumb/dialogue.vue";
import PlaceFormInfo from "@/components/client/place/place-form-info.vue";

export default {
    components: {
        SubVisual,
        ClientPage,
        PageSection,

        RentalSubjectBlockages,
        // RentalSchedulesOnCloud,
        RentalSubjectSchedulesOnCloud,
        // RentalSubjectLimitationPerDate,
        RentalSubjectLimitationPerWeek,

        RentalFormPreview,
        RentalFormCalendar,
        RentalFormSchedules,

        Dialogue,
        PlaceFormInfo,
    },
    props: {
        _subject: { type: String, default: null },
    },
    data: () => ({
        // form
        date: dayjs().add(1, "day").format("YYYY-MM-DD"),
        desc: {
            objective: null,
            procedure: null,
            substance: null,
            headcount: null,
        },
        schedules: [],

        // data
        subject: initRentalSubject(),
        blockages: [],
        schedulesOnCloud: [],
        hasAnScheduleOnDate: false,
        limitationPerDateMet: false,
        limitationPerWeekMet__self: false,
        limitationPerWeekMet__type: false,
        limitationPerWeekMet__site: false,

        // states
        loading_init: false,
        loading_save: false,
        loading_blockages: false,
        loading_schedulesOnCloud: false,
        loading_subjectSchedulesOnCloud: false,
        loading_subjectlimitationPerDate: false,
        loading_subjectlimitationPerWeekSelf: false,
        loading_subjectlimitationPerWeekType: false,
        loading_subjectlimitationPerWeekSite: false,

        showsDialog: false,
    }),
    computed: {
        ...mapGetters(["isBanned"]),

        loading() {
            return this.loading_init || this.loading_save || this.loading_blockages || this.loading_schedulesOnCloud || this.loading_subjectSchedulesOnCloud || this.loading_subjectlimitationPerDate || this.loading_subjectlimitationPerWeekSelf || this.loading_subjectlimitationPerWeekType || this.loading_subjectlimitationPerWeekSite;
        },

        disabled() {
            return !this.subject.rental.isActive;
        },
        disabled_calendar() {
            return 0 < this.schedules.length;
        },

        limitationPerWeekMet() {
            return this.limitationPerWeekMet__self || this.limitationPerWeekMet__type || this.limitationPerWeekMet__site;
        },
        limitationMet() {
            return this.limitationPerDateMet || this.limitationPerWeekMet || this.hasAnScheduleOnDate;
        },

        notRentableMessage() {
            if (this.isBanned) return "사용금지 기간입니다";

            return null;
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            if (this.loading_init) return;
            else this.loading_init = true;

            try {
                let { subject } = await api.v1.rental.subjects.get({ _id: this._subject });
                this.subject = initRentalSubject(subject);
            } finally {
                if (this.loading_init) this.loading_init = false;
            }
        },
        validates() {
            try {
                if (!this.$store.state.payload?._user) {
                    throw new Error("회원만 사용할 수 있습니다");
                }

                if (this.schedules.length < 1) {
                    throw new Error("스케줄을 선택해주세요");
                }

                if (!this.desc.objective) {
                    throw new Error("사용목적을 작성해주세요");
                }

                if (!this.desc.headcount) {
                    throw new Error("사용인원을 작성해주세요");
                }

                return true;
            } catch (error) {
                alert(error.message);
                return false;
            }
        },
        async save() {
            if (!this.validates()) return;

            if (this.loading || this.loading_save) return;
            else this.loading_save = true;

            let { desc } = this;

            this.schedules = await Promise.all(
                this.schedules.map(async (schedule) => {
                    try {
                        return { ...((await api.v1.me.rental.schedules.post({ ...schedule, desc }))?.schedule || {}), success: true };
                    } catch (error) {
                        console.error(error);
                        return { ...schedule, success: false };
                    }
                })
            );
            let hasFailedItem = this.schedules.some(({ success }) => !success);
            if (hasFailedItem) {
                alert("예약 신청이 실패하였습니다.\n성공한 예약을 확인후 다시 신청해주세요.");
            } else {
                alert("예약 신청에 성공하였습니다");
            }

            await (async () => {
                let requests = this.schedules
                    .reduce((requests, { _id, date }) => {
                        if (_id) {
                            let group = requests.find((group) => group.date == date);
                            if (!group) {
                                requests.push({ date, _schedules: [] });
                                group = requests[requests.length - 1];
                            }
                            group._schedules.push(_id);
                        }

                        return requests;
                    }, [])
                    .sort((a, b) => this.$dayjs(a.date).isAfter(b.date));

                let { _subject } = this;

                await Promise.all(
                    requests.map(async (request) => {
                        let { _schedules, date } = request;
                        let item = {
                            _subject,
                            _schedules,

                            rentalType: this.subject?.rental?.type,
                            subjectType: this.subject?.type,

                            date,
                            desc,
                        };
                        try {
                            return { ...((await api.v1.me.rental.requests.post(item)?.request) || {}), success: true };
                        } catch (error) {
                            console.error(error);
                            return { ...item, success: false };
                        }
                    })
                );
            })();

            this.loading_save = false;
            this.showsDialog = true;

            this.$router.push(`/mypage/reservations`);
        },
    },
};
</script>

<style lang="scss" scoped>
.state-bar {
    display: block;
    width: 30px;
    height: 8px;
    border-radius: 999px;
    border: 1px solid var(--v-grey-lighten4);
    margin-left: 8px;
}
@media (min-width: 768px) {
}
</style>

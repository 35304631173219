var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "sh": "공간",
      "tabActive": "공간 소개",
      "image": "/images/place/visual.jpg"
    }
  }), _c('page-section', {
    staticClass: "page-section--first page-section--last",
    attrs: {
      "title": "대관신청",
      "subtitle": "Reservation",
      "button": "대관신청 안내",
      "buttonLink": "/place/introduction#guide"
    }
  }, [_c('v-row', {
    staticClass: "row--xl"
  }, [_vm._l(_vm.subjects, function (item) {
    return [_c('v-col', {
      key: item._id,
      attrs: {
        "cols": "12",
        "md": "6"
      }
    }, [_c('place-list-item', _vm._b({}, 'place-list-item', {
      item
    }, false))], 1)];
  })], 2), _c('div', {
    staticClass: "v-pagination-wrap"
  }, [_c('v-pagination', {
    attrs: {
      "value": _vm.page,
      "length": _vm.pageCount,
      "total-visible": 20
    },
    on: {
      "input": function (page) {
        return _vm.$router.push({
          query: Object.assign({}, _vm.$route.query, {
            page
          })
        });
      }
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-btn', _vm._b({
    attrs: {
      "x-large": "",
      "block": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('click');
      }
    }
  }, 'v-btn', {
    color: _vm.color
  }, false), [_c('span', {
    staticClass: "grey--text text--lighten-1"
  }, [_vm._v(_vm._s(_vm.time.timeStart) + " ~ " + _vm._s(_vm.time.timeEnd))]), _c('v-simple-checkbox', {
    staticClass: "d-block grey--text text--lighten-3",
    attrs: {
      "hide-details": ""
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <v-btn v-bind="{ loading }" :class="{ 'v-btn--active': isChecked }" x-large block outlined color="grey lighten-4" @click="toggleSchedule">
        <span class="v-btn__text"> {{ disabled ? "마감" : "종일" }} </span>
        <v-simple-checkbox :value="isChecked" v-bind="{ disabled }" :class="{ 'd-none': disabled }" dense hide-details color="white" style="pointer-events: none" />
    </v-btn>
</template>

<script>
import { initRentalSubject } from "@/assets/variables";

export default {
    props: {
        value: { type: Array, default: () => [] }, // schedules

        subject: { type: Object, default: initRentalSubject },

        blockages: { type: Array, default: () => [] },
        schedulesOnCloud: { type: Array, default: () => [] },

        limitationMet: { type: Boolean, default: false },

        _user: { type: String },
        date: { type: String, default: Date.now().toDate() },

        loading: { type: Boolean, default: false },
    },
    data: () => ({
        schedules: [],
    }),
    computed: {
        schedule() {
            const { date, _user } = this;
            const { _id: _subject } = this.subject;

            return { _user, _subject, date };
        },

        startsAt() {
            return this.$dayjs(this.date).startOf("date").toISOString();
        },

        endsAt() {
            return this.$dayjs(this.date).endOf("date").toISOString();
        },

        blockage() {
            return this.blockages.find((blockage) => {
                if (!blockage.startsAt && !blockage.endsAt) return true;

                const scheduleStartBeforeBlockageEnd = blockage.endsAt ? this.$dayjs(this.startsAt).isBefore(blockage.endsAt) : true;
                const scheduleEndAfterBlockageStart = blockage.startsAt ? this.$dayjs(this.endsAt).isAfter(blockage.startsAt) : true;

                return scheduleStartBeforeBlockageEnd && scheduleEndAfterBlockageStart;
            });
        },

        isChecked() {
            let { _subject, date } = this.schedule;
            return this.value.some((item) => item._subject == _subject && item.date == date);
        },

        hasDatePassed() {
            return this.$dayjs(this.date).subtract(1, "day").hour(18).minute(0).isBefore(this.$dayjs());
        },

        hasAnSchedule() {
            return this.schedulesOnCloud.some(({ date }) => this.$dayjs(date).isSame(this.$dayjs(this.date), "date"));
        },

        disabled() {
            return !this.isChecked && (this.hasDatePassed || this.hasAnSchedule);
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.schedules = [...this.value];
        },

        emit() {
            this.$emit("input", this.schedules);
        },

        push() {
            this.schedules = [...this.schedules, this.schedule];

            this.emit();
        },

        pull() {
            const { _subject, date, time } = this.schedule;

            const index = this.schedules.findIndex((item) => item._subject == _subject && item.date == date && item.time == time);
            if (index > -1) this.schedules.splice(index, 1);

            this.emit();
        },

        toggleSchedule() {
            if (this.isChecked) this.pull();
            else if (this.hasDatePassed) alert("사용 예약은 전일 18시 이전까지만 가능합니다");
            else if (this.limitationMet) alert("예약할 수 있는 회수를 초과하셨습니다");
            else if (this.disabled) alert("마감된 스케줄입니다");
            else this.push();
        },
    },
};
</script>

<style lang="scss" scoped>
.v-btn:not(.v-btn--fab, .v-btn--icon).v-size--x-large {
    .v-icon {
        color: var(--v-grey-lighten3);
    }
}
.v-btn__text {
    color: var(--v-grey-darken4);
}
.v-btn.v-btn--active {
    .v-btn__text {
        color: #fff;
    }
}
</style>
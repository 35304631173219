<template>
    <rental-form-calendar v-bind="{ ...$attrs, value, subject, color: 'primary' }" @input="(date) => $emit('input', date)" />
</template>

<script>
import dayjs from "@/plugins/vue-dayjs";
import { initRentalSubject } from "@/assets/variables";

import RentalFormCalendar from "@/components/dumb/rental/form/rental-form-calendar.vue";

export default {
    components: {
        RentalFormCalendar,
    },
    props: {
        value: { type: String, default: dayjs().add(1, "day").format("YYYY-MM-DD") },
        subject: { type: Object, default: initRentalSubject },
    },
};
</script>
<style lang="scss" scoped>
::v-deep {
    .theme--light.v-date-picker-table th,
    .v-date-picker-table--date tr th:last-child,
    .v-date-picker-table--date tr td:last-child .v-btn,
    .v-date-picker-table--date tr th:first-child,
    .v-date-picker-table--date tr td:first-child .v-btn {
        color: var(--v-grey-darken4);
    }
    .v-date-picker-table--date tr th:last-child,
    .v-date-picker-table--date tr td:nth-child(7) .v-btn {
        color: #4457ff;
    }
    .v-date-picker-table--date tr th:first-child,
    .v-date-picker-table--date tr td:nth-child(1) .v-btn {
        color: #ff2b2b;
    }
    .v-date-picker-table {
        padding: 0;
    }
    .v-date-picker-table.v-date-picker-table--date {
        thead {
            position: relative;
            &::before {
                content: "";
                display: block;
                position: absolute;
                width: 100%;
                height: 100%;
                border-radius: 999px;
                background-color: #f2f5f9;
                top: 0;
                left: 0;
            }
        }
    }
    .v-date-picker-table .theme--light.v-btn {
        font-weight: 400;
        &.v-btn--rounded {
            border-radius: 6px;
        }
        &.v-btn--outlined {
            border-color: var(--v-grey-lighten3) !important;
        }
        &.v-btn--active {
            color: #fff;
        }
    }
    .v-date-picker-table .theme--light.v-btn.v-btn--disabled {
        color: var(--v-grey-lighten1) !important;
    }
    .v-date-picker-header {
        justify-content: center;
        margin-bottom: 16px;
    }
    .v-date-picker-header__value {
        flex: unset;
        width: 160px;
    }
    .v-date-picker-header .theme--light.v-btn.v-btn--icon {
        border: 1px solid var(--v-grey-lighten3);
    }
    .v-date-picker-header .v-btn:not(.v-btn--text):not(.v-btn--outlined):hover:before {
        opacity: 0;
    }
    .v-date-picker-header .theme--light.v-btn.v-btn--icon i::before {
        color: var(--v-grey-base);
    }
    .theme--light.v-date-picker-header .v-date-picker-header__value:not(.v-date-picker-header__value--disabled) button:not(:hover):not(:focus) {
        color: var(--v-grey-darken4);
    }
    .v-btn:not(.v-btn--outlined).primary {
        color: #fff !important;
    }
    .v-date-picker-table th {
        font-weight: 500;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
    ::v-deep {
        .v-date-picker-header {
            margin-bottom: 20px;
        }
        .theme--light.v-date-picker-header .v-date-picker-header__value:not(.v-date-picker-header__value--disabled) button {
            font-size: 2.4rem;
        }
        .v-date-picker-table th {
            font-size: 1.8rem;
            height: 46px;
            padding: 0;
        }
        .v-date-picker-table td {
            padding: 30px 0;
        }
        .v-date-picker-table tbody tr:first-child td {
            padding-top: 20px;
        }
        .v-date-picker-table tbody tr:last-child td {
            padding-bottom: 0;
        }
        .v-date-picker-table .v-btn {
            font-size: 1.8rem;
            width: 44px;
            height: 44px;
        }
    }
}
@media (min-width: 1200px) {
}
</style>

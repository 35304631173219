var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-btn', _vm._b({
    class: {
      'v-btn--active': _vm.isChecked
    },
    attrs: {
      "x-large": "",
      "block": "",
      "outlined": "",
      "color": "grey lighten-4"
    },
    on: {
      "click": _vm.toggleSchedule
    }
  }, 'v-btn', {
    loading: _vm.loading
  }, false), [_c('span', {
    staticClass: "v-btn__text"
  }, [_vm._v(" " + _vm._s(_vm.disabled ? "마감" : "종일") + " ")]), _c('v-simple-checkbox', _vm._b({
    class: {
      'd-none': _vm.disabled
    },
    staticStyle: {
      "pointer-events": "none"
    },
    attrs: {
      "value": _vm.isChecked,
      "dense": "",
      "hide-details": "",
      "color": "white"
    }
  }, 'v-simple-checkbox', {
    disabled: _vm.disabled
  }, false))], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "sh": "공간",
      "tabActive": "공간소개",
      "image": "/images/place/visual.jpg"
    }
  }), _c('page-section', {
    staticClass: "page-section--first",
    attrs: {
      "title": "공간소개",
      "subtitle": "Place Introduction"
    }
  }, [_c('v-divider', {
    staticClass: "v-divider--dark"
  }), _c('v-sheet', {
    staticClass: "font-size-24 font-size-md-28 py-28 py-md-36 text-center"
  }, [_c('div', [_vm._v("금산에 활력을 불어넣는 여러분이 주인인 공간, 금산 우리동네 이음터를 소개합니다.")])]), _c('v-divider', {
    staticClass: "v-divider--dark"
  })], 1), _c('section', [_c('v-img', {
    attrs: {
      "eager": "",
      "src": "/images/place/place.png"
    }
  })], 1), _c('page-section', {
    staticClass: "page-section--last pt-0"
  }), _c('page-section', {
    staticClass: "page-section--last",
    attrs: {
      "id": "guide",
      "title": "대관신청 안내",
      "subtitle": "Reservation Guide"
    }
  }, [_c('v-img', {
    staticClass: "mx-auto mb-40 mb-md-60",
    attrs: {
      "max-width": "848",
      "src": "/images/place/place-step.png"
    }
  }), _c('v-row', {
    staticClass: "row--sm"
  }, [_vm._l(_vm.items, function (item, index) {
    return [_c('v-col', {
      key: index,
      attrs: {
        "cols": "12",
        "md": "6"
      }
    }, [_c('v-sheet', {
      staticClass: "pa-18 pa-md-24 h-100",
      attrs: {
        "color": "grey lighten-5",
        "rounded": "xs"
      }
    }, [_c('div', {
      staticClass: "tit tit--xs font-weight-bold mb-12 mb-md-16"
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('v-row', {
      staticClass: "row--xxs page-text grey--text"
    }, [_vm._l(item.children, function (child, index) {
      return [_c('v-col', {
        key: index,
        attrs: {
          "cols": "12"
        }
      }, [_c('v-row', {
        attrs: {
          "no-gutters": ""
        }
      }, [_c('v-col', {
        attrs: {
          "cols": "auto"
        }
      }, [child.annotation ? [_vm._v("※")] : [_vm._v("·")], _vm._v(" ")], 2), _c('v-col', [_vm._v(" " + _vm._s(child.text) + " ")])], 1)], 1)];
    })], 2)], 1)], 1)];
  })], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }